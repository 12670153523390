import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Posts from './Posts';

function App() {
  return (
    <Container maxWidth="sm">
      <section>
        <Typography variant="h2">
          From our NariShakti blog
        </Typography>
        <Posts />
      </section>
    </Container>
  );
}

export default App;